function formatData (csvFileAsArrays) {
  const fileRows = [...csvFileAsArrays]
  const listToSend = []
  fileRows.reduce((acc, row) => {
    const dataObj = {}
    dataObj[fileRows[0][0]] = row[0]
    dataObj[fileRows[0][1]] = row[1]
    dataObj[fileRows[0][2]] = row[2]
    dataObj[fileRows[0][3]] = row[3]
    dataObj[fileRows[0][4]] = row[4]
    dataObj[fileRows[0][5]] = row[5]
    dataObj[fileRows[0][6]] = row[6]
    dataObj[fileRows[0][7]] = row[7]
    dataObj[fileRows[0][8]] = row[8]
    dataObj[fileRows[0][9]] = row[9]
    dataObj[fileRows[0][10]] = row[10]
    dataObj[fileRows[0][11]] = row[11]
    dataObj[fileRows[0][12]] = row[12]
    listToSend.push(dataObj)
  })
  return listToSend
}
module.exports = formatData
