import { eventCategoryName } from './dataValidations'

export const SampleFileDriveLink = {
  TUHierarchyMappingUpdateDriveFile: 'https://drive.google.com/file/d/1xNTsi--TkAfUH4m0YNla3aT3hU6ancs3/view?usp=sharing',
  NikshayCreateLoginsDriveFile: 'https://drive.google.com/file/d/1TFoJif6K0SaG7Sl0TB4ljylYQsFT0E-P/view?usp=sharing',
  NikshayIMEIAdditionDriveFile: 'https://drive.google.com/file/d/1RmEqewVM2cTgeXXHZHESn962uuNOx1Zs/view?usp=sharing',
  HUBIMEIAdditionDriveFile: 'https://https://drive.google.com/file/d/1SKAPCllGXpnd9QSGLOS5miiDhQc04Fl3/view?usp=drive_link',
  LoginsWithNewHierarchiesDriveFile: 'https://drive.google.com/file/d/1rvq1S8sVJkLi01RUuFyfTHt6ZrTiI8Y-/view?usp=sharing',
  HUBCreateLoginsDriveFile: 'https://docs.google.com/spreadsheets/d/1fZvHMMKF0pmaQF5o9emarwqerwVFPh4K-N-v3kJS2wE/edit?usp=drive_link',
  CreateHierarchiesHUBDriveFile: 'https://drive.google.com/file/d/1hVvNc00c0ADGazbrj95OkY2StPKZPY8d/view?usp=drive_link',
  AddNewDeployment: 'https://drive.google.com/file/d/1K0RuX3iE6Ab40x5xIP2Dxr1QTeOiDsSX/view?usp=drive_link',
  HUBEngagementDriveFile: 'https://docs.google.com/spreadsheets/d/1vwbGJbOuEwoMYzS8JgoEU9ZXazyfMqfdWQulYW309AY/edit?usp=drive_link',
  HUBTriggerDriveFile: 'https://docs.google.com/spreadsheets/d/12eDsfDtOXfILYUVNkvW1JEdgyEuMJU8F6hBUCAtCiUY/edit?usp=drive_link',
  HUBSuperUserCreationDriveFile: 'https://drive.google.com/file/d/1aJ7bcNLs7Xz-7zt5iqJ0vnwqsqlrgjFL/view?usp=sharing',
  TriggerManagementModule: 'https://docs.google.com/spreadsheets/d/1RsAvnS1GpST3V2hG2Adb0gRRbvfVm6ZQwsjFzHcQ8qA/edit?usp=sharing',
  TasklistMasterList: 'https://docs.google.com/spreadsheets/d/1fWVapSDXdLOnS4DFDTSOyvwd0-7adp9Sx0Bn5BYzoJE/edit#gid=0'
}

export const getSampleFileDriveLink = (requestName) => {
  switch (requestName) {
    case eventCategoryName.TUHierarchyMappingUpdate:
      return SampleFileDriveLink.TUHierarchyMappingUpdateDriveFile
    case eventCategoryName.NikshayCreateLogins:
      return SampleFileDriveLink.NikshayCreateLoginsDriveFile
    case eventCategoryName.LoginsWithNewHierarchies:
      return SampleFileDriveLink.LoginsWithNewHierarchiesDriveFile
    case eventCategoryName.NikshayIMEIAddition:
      return SampleFileDriveLink.NikshayIMEIAdditionDriveFile
    case eventCategoryName.HUBIMEIAddition:
    case eventCategoryName.ASCENTIMEIAddition:
    case eventCategoryName.TANZANIAIMEIAddition:
      return SampleFileDriveLink.HUBIMEIAdditionDriveFile
    case eventCategoryName.HUBCreateLogins:
    case eventCategoryName.ASCENTCreateLogins:
    case eventCategoryName.TANZANIACreateLogins:
      return SampleFileDriveLink.HUBCreateLoginsDriveFile
    case eventCategoryName.HUBCreateHierarchies:
    case eventCategoryName.ASCENTCreateHierarchies:
    case eventCategoryName.TANZANIACreateHierarchies:
      return SampleFileDriveLink.CreateHierarchiesHUBDriveFile
    case eventCategoryName.HUBAddDeployment:
    case eventCategoryName.ASCENTAddDeployment:
    case eventCategoryName.TanzaniaAddDeployment:
      return SampleFileDriveLink.AddNewDeployment
    case eventCategoryName.HUBNewEngagement:
    case eventCategoryName.ASCENTNewEngagement:
    case eventCategoryName.TANZANIANewEngagement:
      return SampleFileDriveLink.HUBEngagementDriveFile
    case eventCategoryName.HUBTriggers:
    case eventCategoryName.ASCENTTriggers:
    case eventCategoryName.TanzaniaTriggers:
      return SampleFileDriveLink.HUBTriggerDriveFile
    case eventCategoryName.HUBCreateSuperUsers:
    case eventCategoryName.ASCENTCreateSuperUsers:
    case eventCategoryName.TANZANIACreateSuperUsers:
      return SampleFileDriveLink.HUBSuperUserCreationDriveFile
    case eventCategoryName.TriggerManagement:
      return SampleFileDriveLink.TriggerManagementModule
    case eventCategoryName.TasklistMasterList:
      return SampleFileDriveLink.TasklistMasterList
  }
}
export const HubAccessTypes = (row) => {
  let result = true
  const list = ['RESTRICTED_VIEW', 'SPL_USER', 'API_SVA', 'HIV_User', 'HIV_Partner', 'HIV', 'TB']
  if (list.indexOf(row) === -1){
    result = false
  }
  return result
}
export const TypeOfPatientsAddedHUB = (row) => {
  let result = true
  const list = ['PublicPrivatePartnership', 'Private', 'CommunityDOTS', 'RNTCP', 'NONE', 'ART']
  if (list.indexOf(row) === -1){
    result = false
  }
  return result
}
export const Headers = {
  SMS: [
    { text: 'Deployment', value: 'deploymentCode', class: 'rounded-l-lg font-weight-black black--text' },
    { text: 'Active Triggers', value: 'TriggersSMS', class: 'font-weight-black black--text' },
    { text: 'Languages', value: 'LanguagesSMS', class: 'font-weight-black black--text' },
    { text: 'Action', value: 'Edit', sortable: false, class: 'rounded-r-lg font-weight-black black--text' }
  ],
  IVR: [
    { text: 'Deployment', value: 'deploymentCode', class: 'rounded-l-lg font-weight-black black--text' },
    { text: 'Active Triggers', value: 'TriggersIVR', class: 'font-weight-black black--text' },
    { text: 'Languages', value: 'LanguagesIVR', class: 'font-weight-black black--text' },
    { text: 'Action', value: 'Edit', sortable: false, class: 'rounded-r-lg font-weight-black black--text' }
  ]
}
export const makeCommaSeperatedListOfLanguages = (item) => {
  var languages = ""
  for (const temp in item) {
    if (!languages.includes(item[temp].language)) {
      languages += item[temp].language + ', '
    }
  }
  languages = languages.substring(0, languages.length - 2)
  return languages
}
export const getTypeOfTrigger = (cronTime) => {
  if (cronTime === '' || cronTime === null) {
    return 'Event'
  } else {
    return 'Time'
  }
}
export const changeCronToTime = (cronTime) => {
  if (cronTime === null || cronTime === '') return 'HH:mm'
  var cron = cronTime.split(" ")
  var minutes = cron[0]
  var hours = cron[1]
  return new Date("1970-01-01 " + hours + ':' + minutes).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

export const changeCronToTimeString = (cronTime) => {
  if (cronTime === null || cronTime === '') return 'HH:mm'
  var cron = cronTime.split(" ")
  var minutes = cron[0]
  var hours = cron[1]
  return new Date("1970-01-01 " + hours + ':' + minutes)
}

export const makeDeploymentTriggersList = (listOfTriggers) => {
  var displayList = []
  var inactiveList = []
  for (let index = 0; index < listOfTriggers.length; index++) {
    const element = listOfTriggers[index]
    var objectSMS = {}
    objectSMS.deploymentCode = element.deploymentCode
    objectSMS.TriggersSMS = 0
    objectSMS.ActiveListSMS = ""
    objectSMS.ActiveListIVR = ""
    objectSMS.LanguagesIVR = ""
    objectSMS.LanguageListIVR = []
    objectSMS.LanguageListSMS = []
    objectSMS.LanguagesSMS = ""
    objectSMS.DisplayLanguageListSMS = ""
    objectSMS.DisplayLanguageListSMS = ""
    objectSMS.TriggersIVR = 0
    element.triggers.forEach(trigger => {
      if (trigger.isActive === true) {
        if (trigger.defaultTemplateId === 0 || (trigger.relatedTemplates.length !== 0 && trigger.relatedTemplates[0].type === "IVR")) {
          objectSMS.TriggersIVR += 1
          objectSMS.ActiveListIVR += trigger.functionName + ', '
        } else {
          objectSMS.ActiveListSMS += trigger.functionName + ', '
          objectSMS.TriggersSMS += 1
        }
      } else {
        inactiveList.push(trigger)
      }
      trigger.relatedTemplates.forEach(template => {
        if (template.type === "SMS") {
          if (!objectSMS.LanguagesSMS.includes(template.language)) {
            objectSMS.LanguagesSMS += template.language + ', '
            objectSMS.LanguageListSMS.push(template.language)
          }
        } else {
          if (!objectSMS.LanguagesIVR.includes(template.language)) {
            objectSMS.LanguagesIVR += template.language + ', '
            objectSMS.LanguageListIVR.push(template.language)
          }
        }
      })
      if (objectSMS.LanguageListSMS.length !== 0) {
        objectSMS.DisplayLanguageListSMS = objectSMS.LanguageListSMS[0]
        if (objectSMS.LanguageListSMS.length > 1) {
          objectSMS.DisplayLanguageListSMS += ' +' + String(objectSMS.LanguageListSMS.length - 1)
        }
      }
      if (objectSMS.LanguageListIVR.length !== 0) {
        objectSMS.DisplayLanguageListIVR = objectSMS.LanguageListIVR[0]
        if (objectSMS.LanguageListIVR.length > 1) {
          objectSMS.DisplayLanguageListIVR += ' +' + String(objectSMS.LanguageListIVR.length - 1)
        }
      }
    })
    objectSMS.ActiveListSMS = objectSMS.ActiveListSMS.substring(0, objectSMS.ActiveListSMS.length - 2)
    objectSMS.ActiveListIVR = objectSMS.ActiveListIVR.substring(0, objectSMS.ActiveListIVR.length - 2)
    objectSMS.LanguagesIVR = objectSMS.LanguagesIVR.substring(0, objectSMS.LanguagesIVR.length - 2)
    objectSMS.LanguagesSMS = objectSMS.LanguagesSMS.substring(0, objectSMS.LanguagesSMS.length - 2)
    displayList.push(objectSMS)
  }
  displayList.sort(function (a, b) {
    return b.TriggersSMS - a.TriggersSMS
  })
  return [displayList, inactiveList]
}

export const getKindOfTrigger = (item) => {
  if (item.defaultTemplateId === 0 || (item.relatedTemplates.length !== 0 && item.relatedTemplates[0].type === 'IVR')) {
    return 'IVR'
  }
  return 'SMS'
}
