<template>
<v-app>
<v-card width="500" class="mx-auto mt-5">
  <v-card-title>
    <h1 class="display-1" style="flex: auto">{{ Heading }}</h1>
  </v-card-title>
  <v-card-text>
    <v-form ref="form" v-model="valid">
      <v-text-field
        v-model="name"
        prepend-icon="mdi-account-circle"
        :rules="nameRules"
        label="Username"
        required
      ></v-text-field>
      <v-text-field
        v-model="password"
        :rules="passwordRules"
        :type="showPassword ? 'text' : 'password'"
        label="Password"
        required
        prepend-icon="mdi-lock"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
      />
      <v-file-input
        accept=".csv"
        label="File"
        :rules="fileRules"
        v-model="file"
        required>
      </v-file-input>
            <v-row>
        <v-col>
          <v-btn
        text
        color="primary"
        class="lowecaseBtn"
        @click="loadSampleFile">
          <v-icon>mdi-file
          </v-icon>
        View sample file</v-btn>
      </v-col>
      <v-spacer/>
      <v-col>
      <v-btn
        class="mr-4"
         @click="submit">submit</v-btn>
      </v-col>
      </v-row>
    </v-form>
  </v-card-text>
  <v-progress-linear
      indeterminate
      color="yellow darken-2"
      v-if="isLoading"
    ></v-progress-linear>
  <v-alert v-if="isError" type="error">
    {{errMsg}}
  </v-alert>
  <v-alert v-if="isOk" type="success">
    {{succMsg}}
  </v-alert>
  <v-divider></v-divider>
</v-card>
</v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from './../router/index'
import { routeNames } from '../../utils/dataValidations'
import { getSampleFileDriveLink } from '../../utils/utils'
export default {
  props: ['Heading'],
  data: () => ({
    valid: false,
    name: '',
    password: '',
    file: null,
    showPassword: false,
    nameRules: [],
    passwordRules: [],
    fileRules: []
  }),
  computed: {
    ...mapState([
      'isAdmin',
      'isLoading',
      'isOk',
      'isError',
      'errMsg',
      'succMsg',
      'isProd',
      'platform',
      'isAuth'
    ])
  },
  mounted: function () {
    if (!this.isAuth) {
      router.push(routeNames.Home)
    }
    this.setDeploymentName(this.$route.query.platform)
    if (this.isProd === null){
      router.push(routeNames.Welcome)
    }
  },
  beforeDestroy: function () {
    this.clearMessage()
  },
  beforeUpdate: function () {
    if (this.platform !== this.$route.query.platform && !!this.platform) {
      this.setDeploymentName(this.$route.query.platform)
      this.clearMessage()
      this.name = ''
      this.password = ''
      this.file = null
      this.nameRules = []
      this.passwordRules = []
      this.fileRules = []
    }
  },
  methods: {
    ...mapActions([
      'setDeploymentName',
      'clearMessage',
      'loading',
      'setErrorMessage'
    ]),
    async submit () {
      this.nameRules = [
        v => !!v || 'Username is required'
      ]

      this.passwordRules = [
        v => !!v || 'Password is required'
      ]

      this.fileRules = [
        v => !!v || 'File is required'
      ]

      if (!this.name || !this.password || !this.file){
        this.setErrorMessage({ isError: true, errMsg: 'Please enter all the fields' })
      }

      if (this.$refs.form.validate()){
        this.clearMessage()
        this.$emit('submit', this.file, this.file.name, this.name, this.password)
      }
    },
    loadSampleFile () {
      const driveLink = getSampleFileDriveLink(this.Heading)
      window.open(driveLink, '_blank')
    }
  }
}
</script>
<style scoped>
.lowecaseBtn {
  text-transform: unset !important;
}
</style>
