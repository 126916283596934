<template>
  <LoginForm Heading = 'Create Logins' @submit="submit"/>
</template>

<script>
import LoginForm from './Form.vue'
import { mapState, mapActions } from 'vuex'
import { CreateLoginValidation } from './../../utils/NikshayValidations/CreateLoginValidation'
import { initializeNikshayMatomo, DataForMatomoTracking, adminLogData } from '../../utils/matomoTracking'
import { apiResponseType, apiResponseValue } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
const formatlogincreationdata = require("./../../utils/NikshayValidations/formatlogincreationdata")
const csv = require("papaparse")

export default {
  components: { LoginForm },
  computed: {
    ...mapState([
      'isProd'
    ])
  },
  mounted: function () {
    initializeNikshayMatomo()
  },
  methods: {
    ...mapActions([
      'loading',
      'setErrorMessage',
      'success'
    ]),
    async submit (file, filename, username, password) {
      this.loading(true)
      this.setErrorMessage({ isError: false, errMsg: '' })
      this.success({ isOk: false, succMsg: '' })
      let apiResponse = apiResponseType.FAILED
      let data = null
      const parsedCSV = csv.parse(file, {
        complete: async function (results){
          const validationError = CreateLoginValidation(results.data)
          if (validationError){
            this.setErrorMessage({ isError: true, errMsg: validationError })
            this.loading(false)
          } else {
            data = formatlogincreationdata(results.data)
            let jsonUploadResponse
            try {
              jsonUploadResponse = await ApiClient.post(
                username,
                password,
                JSON.stringify(data),
                '/api/UserFacility/CreateLogins'
              )
            } catch (error) {
              this.setErrorMessage({ isError: true, errMsg: apiResponseType.JSONUploadFailed })
            }
            this.loading(false)
            if (jsonUploadResponse){
              if (jsonUploadResponse.data.data === apiResponseValue.LoginsAreCreated){
                apiResponse = apiResponseType.SUCCESS
                this.setErrorMessage({ isError: false, errMsg: '' })
                this.success({ isOk: true, succMsg: 'Logins are created.' })
              } else if (jsonUploadResponse.data.data === apiResponseValue.CsvFileNotReceivedOrIsInvalid){
                this.setErrorMessage({ isError: true, errMsg: 'File was not received or rejected by Nikshay API' })
              } else if (jsonUploadResponse.data.data === apiResponseValue.InvalidAccess){
                this.setErrorMessage({ isError: true, errMsg: 'This User do not have Permission to Create Logins.' })
              } else if (jsonUploadResponse.data.data === apiResponseValue.UserIsNotAuthenticated){
                this.setErrorMessage({ isError: true, errMsg: 'This User is not Authenticated, Please use latest token while creating logins.' })
              } else if (jsonUploadResponse.error != null){
                this.setErrorMessage({ isError: true, errMsg: 'An exception has occurred.' })
              } else if (Array.isArray(jsonUploadResponse.data.data)) {
                var arrayobj = jsonUploadResponse.data.data

                var outputlist = arrayobj.map(function (item) {
                  return "[" + item.username + " : " + item.hierarchyId + "]" + "\n"
                })

                apiResponse = apiResponseType.PARTIALSUCCESS
                this.setErrorMessage({ isError: true, errMsg: outputlist + ' following Usernames with HierarchyIds were not added, please check if the Username is not present in DB and you have entered correct values for AccessType, TypeOfPatientsAdded and TypeOfStaffAllowedToAdd.' })
              } else {
                this.setErrorMessage({ isError: true, errMsg: 'Something went wrong' })
              }
              adminLogData('Create Logins', 'StatusCode: ' + jsonUploadResponse.status, 'Filename: ' + filename)

              if (apiResponse === apiResponseType.PARTIALSUCCESS){
                const SuccessList = data.filter(inputData => !jsonUploadResponse.data.data.some(responseArray => (inputData.Username === responseArray.username && parseInt(inputData.HierarchyId) === responseArray.hierarchyId)))
                DataForMatomoTracking('Create Logins', apiResponseType.SUCCESS, SuccessList)
                DataForMatomoTracking('Create Logins', apiResponseType.FAILED, jsonUploadResponse.data.data)
              } else {
                DataForMatomoTracking('Create Logins', apiResponse, data)
              }
            }
          }
        }.bind(this)
      })
    }
  }
}
</script>
